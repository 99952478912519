.map-series {
  /* background-image: url(../../assets/images/contact-banner-bg.jpg); */
  padding: 10px 10%;
  text-align: center;
  opacity: 0.9;
}

.map-series h2 {
  color: #50160f;
  margin-bottom: 2px;
  font-size: 50px;
}

.map-series video {
  max-width: 100%;
  height: 600px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .map-series h2 {
      font-size: 40px; /* Adjust font size for medium screens */
  }

  .map-series video {
      height: 500px; /* Adjust video height for medium screens */
  }
}

@media (max-width: 768px) {
  .map-series h2 {
      font-size: 35px; /* Adjust font size for small screens */
  }

  .map-series video {
      height: 400px; /* Adjust video height for small screens */
  }
}

@media (max-width: 480px) {
  .map-series h2 {
      font-size: 30px; /* Adjust font size for extra small screens */
  }

  .map-series video {
      height: 300px; /* Adjust video height for extra small screens */
  }
}
