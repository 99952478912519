.destination-details {
    width: 80%;
    margin: 2rem auto;
    text-align: center;
}

.destination-details h1 {
    color: #50160f;
}

.destination-details img {
    width: 80%;
    height: 350px;
    object-fit: cover;
}

.sub-destination img {
    width: 48%;
    height: 250px;
    object-fit: cover;
    margin: 1%;
}

.sub-destination h2 {
    color: #50160f;
    margin-top: 20px;
    text-align: center;
}

.sub-destination p {
    text-align: center;
    margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .destination-details img {
        width: 90%; /* Adjust image width for medium screens */
        height: 300px; /* Adjust image height for medium screens */
    }

    .sub-destination img {
        width: 48%;
        height: 200px; /* Adjust image height for medium screens */
    }
}

@media (max-width: 768px) {
    .destination-details {
        width: 90%; /* Adjust container width for small screens */
    }

    .destination-details img {
        width: 100%; /* Adjust image width for small screens */
        height: 250px; /* Adjust image height for small screens */
    }

    .sub-destination img {
        width: 100%; /* Adjust image width for small screens */
        height: 180px; /* Adjust image height for small screens */
        margin: 0.5% 0; /* Adjust margin for small screens */
    }
}

@media (max-width: 480px) {
    .destination-details {
        width: 95%; /* Adjust container width for extra small screens */
    }

    .destination-details img {
        width: 100%; /* Adjust image width for extra small screens */
        height: 200px; /* Adjust image height for extra small screens */
    }

    .sub-destination img {
        width: 100%; /* Adjust image width for extra small screens */
        height: 150px; /* Adjust image height for extra small screens */
        margin: 0.5% 0; /* Adjust margin for extra small screens */
    }

    .sub-destination h2 {
        font-size: 1.2rem; /* Adjust font size for extra small screens */
    }

    .sub-destination p {
        font-size: 0.9rem; /* Adjust font size for extra small screens */
    }
}
