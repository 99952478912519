.hotel-details-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.hotel-details-container h1 {
    color: #50160f;
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.hotel-address {
    color: #50160f;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
}

.hotel-main-image {
    width: 80%;
    height: 400px;
    margin: 20px 0;
    border-radius: 10px;
    object-fit: cover;
}

.hotel-description {
    color: black;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.hotel-images-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 20px;
}

.hotel-small-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .hotel-main-image {
        width: 90%;
        height: 350px;
    }

    .hotel-images-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .hotel-details-container {
        width: 95%;
        padding: 15px;
    }

    .hotel-details-container h1 {
        font-size: 2rem;
    }

    .hotel-address, 
    .hotel-description {
        font-size: 1rem;
    }

    .hotel-main-image {
        width: 100%;
        height: 300px;
    }

    .hotel-images-grid {
        grid-template-columns: 1fr;
    }

    .hotel-small-image {
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .hotel-details-container h1 {
        font-size: 1.8rem;
    }

    .hotel-address, 
    .hotel-description {
        font-size: 0.9rem;
    }

    .hotel-main-image {
        height: 250px;
    }
}
