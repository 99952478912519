.services-outer {
    width: 100%;
    background-color: #f3f1ee;
    display: flex;
    justify-content: center;
    padding: 30px 30px;
    /* background: url(../../assets/images/contact-banner-bg.jpg); */
}

.services-title {
    text-align: center;
    margin-bottom: 40px;
}

.services-title h2 {
    margin-top: 2rem;
    color: #50160f;
    font-size: 2.5rem;
}

.services-list {
    display: flex;
    gap: 70px; /* Increase the gap between items */
    justify-items: center;
    margin-bottom: 2rem;
}

.service {
    text-align: center;
}

.service .icon {
    font-size: 2.5rem;
    color: #50160f;
    margin-bottom: 10px;
}

.service p {
    font-size: 1.5rem;
    color: #333;
}

/* Responsive Design */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .services-list {
        flex-direction: row; /* Default layout for large screens */
        gap: 70px; /* Maintain gap for larger screens */
    }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991px) and (min-width: 768px) {
    .services-list {
        flex-direction: row; /* Maintain row layout for tablets */
        gap: 40px; /* Adjust gap for medium screens */
    }
}

/* Small devices (mobile, 576px and up) */
@media (max-width: 767px) {
    .services-list {
        flex-direction: column; /* Stack items vertically on mobile */
        gap: 20px; /* Adjust gap for smaller screens */
    }
    .services-title h2 {
        font-size: 2rem; /* Adjust font size for mobile */
    }
    .service .icon {
        font-size: 2rem; /* Reduce icon size for mobile */
    }
    .service p {
        font-size: 1.2rem; /* Adjust font size for mobile */
    }
}

/* Extra small devices (phones in portrait mode, less than 576px) */
@media (max-width: 575px) {
    .services-title h2 {
        font-size: 1.8rem; /* Further adjust font size for very small screens */
    }
    .service .icon {
        font-size: 1.8rem; /* Further reduce icon size */
    }
    .service p {
        font-size: 1rem; /* Further adjust font size */
    }
}
