
.navbar {
  background: linear-gradient(90deg, rgba(242, 239, 238, 0.678) 0%, rgba(255, 248, 240, 0.674) 100%);
  /* background: url('../../assets/images/nav-bg1.jpg') no-repeat center center; */
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: 'Kanit', sans-serif;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  /* margin-left: 30px; */
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  /* align-items: center; */
  
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(10, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
  align-items: center;
  justify-content: center;
}

.nav-links {
  color: #892e24;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #ff9933;
  transition: all 0.2s ease-out;
  color: #ff9933;
}

.fa-bars {
  color: #ff9933;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
.navbar-logo {
  display: flex;
  /* align-items: left; */
  /* justify-content: center; */
  cursor: pointer;
}

.navbar-logo-img {
  width: auto; /* Adjust the size as needed */
  height: 120px;
  margin-top: -20px;
}

/* New CSS for Dropdown Menu */
.dropdown-menu {
  position: absolute;
  display: none;
  background-color: #fffcfab7;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-menu li {
  display: block;
}

.dropdown-menu li a {
  color: #892e24;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu li a:hover {
  background-color: #ff9933;
  color: #fff;;
}

.nav-item:hover .dropdown-menu {
  display: block;
}

/* Add this CSS for mobile */
.nav-item.mobile-reserve {
  display: none;
}

.navbar-container button{
  margin-left: -50px;
}

@media screen and (max-width: 960px) {
/* To design the Header logo for responsive */
  .navbar-logo {
    display: flex;
    align-items: left;
    cursor: pointer;
    margin-top: -40px;
    margin-inline-start: -20px;  
}
  
  .navbar-logo-img {
    width: auto; /* Adjust the size as needed */
    margin-top: -35px;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Center items horizontally */
    justify-content: start;  /* Center items vertically */
    width: 100%;
    height: 100vh;
    overflow-y: auto; /* Enable scrolling if content exceeds viewport height */
    position: absolute;
    top: 80px;
    left: -150%;
    opacity: 1;
    background: #fff;  /* Ensure background color */
    transition: all 0.2s ease-out;
    z-index: 1;
    }

  .nav-menu.active {
    background: linear-gradient(90deg, rgb(250, 248, 248) 0%, rgb(242, 239, 238) 100%);
    left: 0;
    opacity: 1;
    transition: all 0.2s ease-out;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #ff9933;
    color: #fff;
    border-radius: 12px;
    transition: all 0.2s ease-out;
    border-bottom: 4px solid #ff9933;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #ff9933;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #892e24;
    padding: 14px 20px;
    border: 1px solid #892e24;
    transition: all 0.2s ease-out;
  }

  .nav-links-mobile:hover {
    background: #ff9933;
    color: #fff;
    transition: all 0.2s ease-out;
  }
  .nav-links-mobile {
    margin-top: 5px;
    font-size: 1.2rem;

  }
  .nav-item.mobile-reserve {
    display: block;
    width: 100%;
    text-align: center;
    margin: 5px 0;
  }

}
