:root {
    --primary: #ff9933;
    --hover:#892e24;
}

.btn {
    padding: 8px 20px;
    border-radius: 8px;
    outline: none;
    border: none;
    cursor: pointer;
    display: inline-flex; /* Ensure the icon and text are in the same line */
    align-items: center; /* Center align items */
    gap: 8px; /* Space between icon and text */
    font-family: 'Kanit', sans-serif;
    transition: all 0.3s ease-out;
}

.btn--primary {
    background-color: var(--hover);
    color: #fff;
    border: 2px solid var(--hover);
}

.btn--outline {
    background-color: var(--primary);
    color: #fff;
    border: 1px solid var(--primary);
}

/* btn 1 style */
.btn1 {
    margin-top: 10px;
    padding: 15px 40px;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 8px;
}

.btn1::after {
    content: "";
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 70%;
    background-color: #892e24;
    /* background-color: #e3780e; */
    left: 0;
    right: 0;
    border-radius: 3px;
}

.btn1::before {
    content: "";
    /* background: linear-gradient(45deg, rgba(248, 17, 17, 0.904), rgba(249, 73, 10, 0.742), #ff9933d2, rgba(241, 180, 49, 0.429), rgb(244, 242, 103), #f6554373, #f4a04b, rgba(252, 45, 45, 0.629)); */
    background: linear-gradient(45deg, 
  rgba(255, 119, 1, 0.7), 
  rgba(246, 39, 39, 0.9), 
  #cb6600, 
  rgba(255, 247, 1, 0.642), 
  rgba(255, 1, 1, 0.6),
  rgb(98, 98, 3), 
  #ff6601, 
  #d77f03
);

    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 600%;
    z-index: -1;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    filter: blur(8px);
    animation: glowing 20s linear infinite;
    transition: opacity .3s ease-in-out;
    border-radius: 3px;
    opacity: 1;  /* put 0 to stop animation */
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.btn1:hover::before {
    opacity: 1;
}

.btn1:active::after {
    background: transparent;
}

.btn--medium {
    padding: 5px 60px;
    font-size: 1rem;
}

.btn--large {
    padding: 12px 90px;
    font-size: 1rem;
}

.btn--medium:hover,
.btn--large:hover {
    /* background: var(--primary);
    color: #fff; */
    background-color: transparent;
    color: var(--primary);
    border: 1px solid var(--primary);
}

.btn--outline:hover{
    background-color: transparent;
    color: var(--hover);
    border: 2px solid var(--hover);
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
    .btn--medium {
        font-size: 0.9rem;
        padding: 6px 18px;
    }
    .btn--large {
        font-size: 1.1rem;
        padding: 10px 24px;
    }
}

@media (max-width: 768px) {
    .btn--medium {
        font-size: 0.8rem;
        padding: 4px 16px;
    }
    .btn--large {
        font-size: 1rem;
        padding: 8px 22px;
    }
}

@media (max-width: 576px) {
    .btn--medium {
        font-size: 0.75rem;
        padding: 4px 14px;
    }
    .btn--large {
        font-size: 0.9rem;
        padding: 6px 20px;
    }
}
