/* src/pages/PackagePage.css */

.package-page {
    justify-content: center; /* Center align content horizontally */
    align-items: flex-start; /* Align items at the start vertically */
    background: url('../assets/images/pkg-page-bg.jpg') no-repeat center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  
  .package-header {
    text-align: center;
    width: 100%; /* Ensure header takes full width */
    margin-bottom: 20px; /* Add margin bottom for spacing */
    /* background-image: url('../assets/images/pkg-banner-section-bg.jpg') ; */
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    /* padding-top: 20px; */
    padding-bottom: 20px;
  }
  .package-details{
    text-align: center;
    width: 100%; /* Ensure header takes full width */
    margin-bottom: 20px; /* Add margin bottom for spacing */
    align-items: center;
    /* margin-left: 11%; */
  }
  
  .package-image {
    width: 100%;
    height: 60vh;
    margin-bottom: 20px;
  }
  
  .package-content-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center align columns horizontally */
    width: 100%; /* Ensure wrapper takes full width */
    max-width: 80%; /* Set maximum width to 80% of the viewport */
    margin: 0 auto; /* Center align the wrapper */
    margin-bottom: 100px;
}
  
  .package-column-left {
    flex: 0 0 70%;
    padding: 20px;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
  }
  
  .package-column-right {
    flex: 0 0 30%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .day-activity {
    margin-bottom: 20px; /* Adjust spacing between day activities */
    font-family: 'Kanit', sans-serif;

  }
  
  .day-activity h3 {
    font-size: 16px; /* Adjust font size for day titles */
    color: #fff;    /* Adjust color for day titles */
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    background-color:#892e24;
  }
  
  .day-activity ul {
    list-style-type: disc; /* Set bullet points */
    margin-left: 20px; /* Add left margin for bullet points */
  }
  
  .day-activity ul li {
    margin-bottom: 5px; /* Adjust spacing between bullet points */
    font-size: 14px;
    margin-top: 5px;
  }
  
  .pkg-highlights-text{
    list-style: disc;
    font-family: 'Kanit', sans-serif;

  }
  
  .package-content {
    /* margin-top: 10px; */
    width: 100%; /* Ensure content takes full width */

  }

  .package-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .package-nav button {
    width: 75%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #892e24;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #892e24;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    transition: background-color 0.3s;
    font-family: 'Kanit', sans-serif;
    height: 50px; /* Set a fixed height for buttons */
    box-sizing: border-box; /* Ensure padding and border are included in height */

  }

  .package-nav button:hover {
    padding: 10px 20px;
    cursor: pointer;
    background-color: #892e24;
    color: #fff;
  }
  
  .package-nav button.active {
     background-color: #892e24; /* Style for active state */
     color: #fff;
  }

  .package-nav button .button-icon {
    font-size: 14px; /* Icon size */
    text-decoration: none;
    gap: 10px;
  }


  .transport-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .transport-img-section{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .transport-option {
    width: 45%;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .transport-option img {
    width: 100%;
    height: 80px;
    border-radius: 10px;
  }
  
  .transport-option p {
    margin-top: 10px;
    text-align: center;
  }

  .reserve-now-section {
    display: flex;
    /* margin-top: 50px; */
    /* margin-bottom: 50px; */
  }
  
  .reserve-banner {
    flex: 0 0 70%;
  }
  
  .reserve-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-radius: 8px; */
  }
  
  .reserve-form {
    flex: 0 0 30%;
  }

   /* Ensure responsiveness for smaller screens */
   
   @media (max-width: 768px) {
    .package-column-left,
    .package-column-right {
      flex: 1 1 100%;
      padding: 10px;
      border-right: none;
    }
    .transport-img-section{
      flex-direction: row;
      align-items: center;
    }
    .transport-options{
      flex-direction: column;
      align-items: center;
    }
    .transport-options h2{
      font-size: 3rem;
      text-align: center;
    }
    .transport-option {
      width: 50%;
      margin-bottom: 20px;
      text-align: center;
    }
    .transport-option img {
      width: 100%;
      height: 100px;
      border-radius: 10px;
    }
    
    .transport-option p {
      margin-top: 5px;
      text-align: center;
  
    }

    .reserve-now-section {
      flex-direction: column;
      align-items: center;
    }
  
    .reserve-banner,
    .reserve-form {
      flex: 1 1 100%;
      padding: 0;
    }
  
    .reserve-banner-image {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .package-column-left,
    .package-column-right {
      flex: 1 1 100%;
      padding: 10px;
      border-right: none;
    }
    
    .transport-img-section {
      flex-direction: row;
      align-items: center;
    }

    .transport-options{
      flex-direction: column;
      align-items: center;
    }
    .transport-options h2{
      font-size: 2rem;
      text-align: center;
    }
    .transport-option {
      width: 50%;
      margin-bottom: 20px;
      text-align: center;
    }
    .transport-option img {
      width: 100%;
      height: 120px;
      border-radius: 10px;
    }
    
    .transport-option p {
      margin-top: 5px;
      text-align: center;
  
    }

    .reserve-now-section {
      flex-direction: column;
      align-items: center;
    }
  
    .reserve-banner,
    .reserve-form {
      flex: 1 1 100%;
      padding: 0;

    }
  
    /* .reserve-banner-image {
      margin-bottom: 20px;
    } */

    .reserve-banner-image {
      width: 100%;
      height: auto;
      /* margin-bottom: 20px; */
      margin-top: 20px;
    }
  }
  
  @media (max-width: 1024px) {
  
    .reserve-now-section {
      flex-direction: column;
      align-items: center;
    }
  
    .reserve-banner,
    .reserve-form {
      flex: 1 1 100%;
      padding: 0;
    }
  
    .reserve-banner-image {
      margin-bottom: 20px;
    }
  }
  
  
  @media (max-width: 480px) {
    .reserve-banner-image {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
    }
  }