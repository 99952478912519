/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

body {
  /* font-family: 'Times New Roman', Times, serif;  Default font for the entire body */
  /* font-family: 'Noto Sans SC', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Kanit', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  font-weight: 400;
}

/* Optionally, set default styles for headings and paragraphs */
h1, h2 {
  /* font-family: 'Aclonica', sans-serif; */
  /* font-family: 'Noto Sans SC', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Kanit', sans-serif;

  /* text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3); Example drop shadow */
  color: #0b0b0b;
  font-size: 50px;
  font-weight: 900;
  /* font-family: 'Pacifico', cursive; */
}

/* Optionally, set default styles for headings and paragraphs */
h3, h4, h5, h6 {

  /* font-family: 'Neuton', sans-serif; */
  /* font-family: 'Noto Sans SC', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */

  font-family: 'Kanit', sans-serif;
  /* text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3); Example drop shadow */
  font-weight: 900;
  font-size: 38px;
  /* color: #0b0b0b; */
}

p {
  /* font-family: 'Noto Sans SC', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Neuton'; */
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  font-weight: 100;
  /* color: #0b0b0b; */

}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

