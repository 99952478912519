.image-banner {
    position: relative;
    width: 100%;
    height: 500px; /* Adjust the height as needed */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../assets/images/destination-banner1.jpg') no-repeat center center;
    background-size: cover; /* Ensures the image covers the entire banner area */
}

.image-banner__overlay {
    position: relative;
    z-index: 1;
    text-align: center;
}

.image-banner__text {
    color: white;
    font-size: 3em; /* Adjust the font size as needed */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Responsive Design */
@media (max-width: 1024px) {
    .image-banner {
        height: 200px; /* Adjust height for medium screens */
    }

    .image-banner__text {
        font-size: 2.5em; /* Adjust font size for medium screens */
    }
}

@media (max-width: 768px) {
    .image-banner {
        height: 150px; /* Adjust height for small screens */
    }

    .image-banner__text {
        font-size: 2em; /* Adjust font size for small screens */
    }
}

@media (max-width: 480px) {
    .image-banner {
        height: 100px; /* Adjust height for extra small screens */
    }

    .image-banner__text {
        font-size: 1.5em; /* Adjust font size for extra small screens */
    }
}
