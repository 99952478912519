.packages {
  padding: 3rem;
  padding-top: 0;
  background: #fff;
  background: url('../../assets/images/pkg-list-bg1.jpeg') no-repeat center center;
  background-size: cover;
  padding-top: 50px;
}

.packages h1 {
  text-align: center;
  color: #0b0b0b;
}

.packages__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.packages__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.packages__wra0per ul {
  list-style: none;
}

.packages__list {
  margin-bottom: -30px;
  gap: 10px;
  position: relative; /* Ensure relative positioning for absolute children */

}

.packages__list .package__list_link {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  height: 500px;
  width: 300px;
  background-color: #ffffffd5;
  align-items: center;
  position: relative; /* Add position relative to parent for positioning child */
}

.package__list_link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 5px;
  overflow: hidden;
  text-decoration: none;
}

.package__list__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  height: 300px;
  border-radius: 10px 10px 0 0;
  overflow: visible;
  
}

/* .fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
} */


.package__list__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
  border-radius: 5px 5px 0 0;
}
/* to add hover effect for package img */
.package__list__img:hover {
  transform: scale(1.1);
}

.package__list__pic-wrap:hover .middle {
  opacity: 1;
}

.middle {
  position: absolute;
  top: 0; /* Adjusted top to cover the entire image */
  left: 0; /* Adjusted left to cover the entire image */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Background color with opacity */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.5s ease; /* Smooth transition for opacity */
}
.middle:hover{
  opacity: 1;
}

.text {
  background-color: #ff993327;
  color: #fff;
  font-size: 14px;
  padding-top: 10%;
  text-align: center;
  border-radius: 5px;
  height: 100%;
  width:100%;
  background-size: cover;
  font-family: 'Kanit', sans-serif;

}

/* end */

.package__list_info {
  padding-top: 40px;
  padding-bottom: 30px;
  align-items: center;
  display: flex;
  flex-direction: column; /* Default is row for larger screens */
  overflow: hidden;
}

.package__list__text {
  font-size: 22px;
  line-height: 24px;
  text-shadow: none;
  color: #0b0b0b;
  margin-bottom: 10px;
}

.package__list__descrip__text{
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  line-height: 18px;
  text-shadow: none;
  color: #2e2e2ed3;
  text-align: justify;
  font-weight: 100;

}

.package__list_info button{
  align-items: center;
}

.package__list__content {
  color: #010101;
  font-size: 14px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .packages__list {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .packages__list .package__list_link {
    margin-bottom: 2rem;
  }
}

/* New styles for the overlay box */
.package__overlay {
  position: absolute;
  bottom: -20px;
  left: 25px;
  width: 80%; /* Span the entire width of the parent */
  background:#2a490a; /* Adjust background color and opacity */
  /* background: rgba(192, 46, 5, 0.893); */
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px; /*Rounded corners at the bottom*/
}
.package__info {
  display: flex;
  align-items: center;
}

.package__icon {
  margin-right: 5px;
  font-size: 14px;
}

.package__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: small;
  font-family: 'Kanit', sans-serif;

}