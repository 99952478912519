.gallery-container {
  background-image: url('../../assets/images/bGGG.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.destination-section {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 1rem;
}

.gallery-section {
  width: 80%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  overflow-y: auto;
}

.gallery-heading {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.destination-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.destination-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem 1.5rem;
  font-size: 1.3rem;
  color: #333;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  text-align: center;
}

.destination-item:hover {
  background-color: rgba(255, 255, 255, 1);
  transform: scale(1.05);
}

.destination-gallery {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.image-gallery {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
}

.video-gallery {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #FFE4C4;
  border-radius: 8px;
}

.image-grid, .video-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.gallery-image, .gallery-video {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}
