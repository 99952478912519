/* BlogPage.css */
.blog-page {
    padding: 40px; /* Increase padding for more space around the content */
    max-width: 900px; /* Slightly wider for a more open layout */
    margin: 0 auto;
    background-color: #f9f9f9; /* Light background color for contrast */
    border-radius: 10px; /* Rounded corners for a softer look */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.blog-page h1 {
    margin-bottom: 25px;
    font-size: 2.5rem; /* Larger title for impact */
    font-family: 'Merriweather', serif; /* Elegant serif font for headings */
    color: #333;
    text-align: center; /* Centered heading */
    border-bottom: 2px solid #851106; /* Accent border for emphasis */
    padding-bottom: 10px;
}

.blog-page-image {
    width: 100%;
    height: auto;
    margin-bottom: 30px; /* More space below the image */
    border-radius: 10px; /* Rounded corners on images */
    transition: transform 0.3s ease; /* Smooth image hover effect */
}

.blog-page-image:hover {
    transform: scale(1.05); /* Slight zoom on hover */
}

.blog-page p {
    font-size: 1.15rem; /* Slightly larger text for readability */
    line-height: 1.8; /* Increase line height for better readability */
    color: #555; /* Softer text color */
    margin-bottom: 20px; /* More space between paragraphs */
    font-family: 'Open Sans', sans-serif; /* Clean and modern font for body text */
}

.blog-page h4 {
    font-size: 1.5rem; /* Distinct size difference from paragraphs */
    color: #851106; /* Accent color for headers */
    margin-top: 30px; /* More space before headers */
    margin-bottom: 15px; /* Space after headers */
    font-family: 'Merriweather', serif; /* Consistent with main title */
    border-left: 4px solid #851106; /* Accent line for visual interest */
    padding-left: 10px;
}

/* Add a subtle fade-in effect for content */
.blog-page h1,
.blog-page p,
.blog-page h4 {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 0.8s ease-out forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
