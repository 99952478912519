.AUbanner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.AUbanner-content {
    width: 80%; /* Set content width to 80% */
    margin: 0 auto; /* Center the content horizontally */
    text-align: center; 
    padding: 2rem;
}

.AUbanner-text {
    text-align: center;
    color: #50160f; 
    padding: 1rem;
}

.AUbanner h1 {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #50160f; 
}

.AUbanner p {
    font-size: 1.3rem;
    font-weight: 200;
    text-align: center;
    color: #50160f; 
}

/* Responsive Design */
@media (max-width: 1024px) {
    .AUbanner-content {
        padding: 1.5rem;
    }

    .AUbanner h1 {
        font-size: 2.5rem;
    }

    .AUbanner p {
        font-size: 1.2rem;
    }
}

@media (max-width: 768px) {
    .AUbanner h1 {
        font-size: 2rem;
    }

    .AUbanner p {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .AUbanner-video {
        height: 20vh; /* Further reduce video height for mobile */
    }

    .AUbanner h1 {
        font-size: 1.8rem;
    }

    .AUbanner p {
        font-size: 0.9rem;
    }
}
