/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} 
body{
    background: rgb(160, 156, 156);
    background: black;
} */

#section-wrapper{
    width: 100%;
    padding: 10px;
    margin-top: 100px;
    margin-bottom: 100px;
}
.box-wrapper{
    position: relative;
    display: table;
    width: 1100px;
    margin: auto;
    border-radius: 10px;
}
.info-wrap{
    width: 35%;
    height: 545px;
    padding: 40px;
    float: left;
    display: block;border-radius: 30px 0px 0px 30px;
    background: linear-gradient(144deg,rgb(195, 194, 191)0%,rgba(227, 202, 193, 0.503)100%);
    color: white;
}
.info-title{
    text-align: left;
    font-size: 28px;
    letter-spacing: 0.5px;
}

.con2 h4{
    color: #050505;
    text-align: center;
    font-size: 40px;
    margin-top: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.con2 h5{
    color: rgb(25, 149, 198);
    text-align: center;
    font-size: 30px;
    margin-top: 70px;
    font-family: Georgia, 'Times New Roman', Times, serif;

}
.con2 h6{
    color: #050505;
    text-align: center;
    font-size: 20px;
    font-family: Georgia, 'Times New Roman', Times, serif;

}
.info-sub-title{
    font-size: 18px;
    font-weight: 300;
    margin-top: 17px;
    letter-spacing: 0.5px;
    line-height: 26px;
    color: #050505;
    font-weight: bolder;
}
.info-details{
    list-style: none;
    margin: 60px 0px;
    color: #050505;
}
.info-details li{
    margin-top: 25px;
    font-size: 18px;
    color: rgb(3, 3, 3);
}
.info-details li i{
    background: rgb(200, 121, 92);
    padding: 12px;
    border-radius: 50%;
    margin-right: 5px;

}
.info-details li a{
    color: rgb(5, 4, 4);
    text-decoration: none;
    font-weight: bolder;

}
.info-details li a:hover{
    color: rgb(222, 182, 107);
}
.social-icons{
    list-style: none;
    text-align: center;
    margin: 20px 0px;
}
.social-icons li{
    display: inline-block;
}
.social-icons li i{
    background: rgb(192, 141, 73);
    color: white;
    padding: 15px;
    font-size: 20px;
    border-radius: 22%;
    margin: 0px 5px;
    cursor: pointer;
    transition: all .5s;
}
.social-icons li i:hover{
    background: white;
    color: black;
}
.form-wrap{
    width: 65%;
    float: right;
    padding: 40px 24px 35px 25px;
    border-radius: 0px 30px 30px 0px;
    background: rgba(216, 212, 209, 0.557);
    height: 545px;

}
.form-title{
    text-align: left;
    margin-left: 23px;
    font-size: 28px;
    letter-spacing: 0.5px;
}
.form-fields{
    display: table;
    width: 100%;
    padding: 15px 5px 5px 5px;
}
.img1{
    margin-left: 150px;
}
.img2{
    margin-left: 150px;
}
.img3{
    margin-left: 150px;
}

.form-fields input,
.form-fields textarea{
    border: none;
    outline: none;
    background: none;
    font-size: 18px;
    color: gray;
    padding: 20px 10px 20px 5px;
    width: 100%;
}
.form-fields textarea{
    height: 150px;
    resize: none;
}
.form-group{
    width: 46%;
    float: left;
    padding: 0px 30px;
    margin: 14px 12px;
    border-radius: 25px;
    box-shadow: inset 8px 8px 8px rgb(198, 145, 116) , inset -8px -8px 8px white;
}
.form-fields .form-group:last-child{
    width: 96%;
}
.submit-button{
    width: 40%;
    height: 60%;
    margin: 0px 12px;
    padding: 10px;
    border-radius: 10px;
    font-size: 20px ;
    font-weight: 700;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
    text-align: center;
    background: rgb(201, 118, 88);
    box-shadow: 3px 3px 8px brown, -3px -3px 8px white; 
}
.submit-button:hover{
    background: rgb(186, 118, 105);
}

@media only screen and (max-width:767px){
    .box-wrapper{
        width: 100%;
    }
    .info-wrap,
    .form-wrap{
        width: 100%;
        height: inherit;
        float: none;
    }
    .info-wrap{
        border-radius: 30px 30px 0px 0px;
    }
    .form-wrap{
        border-radius: 0px 0px 30px 30px;
    }
    .form-group{
        width: 100%;
        float: none;
        margin: 25p x 0px;
    }
    .form-fields .form-group:last-child,
    .submit-button{
        width: 100%;
    }
}
@media (max-width: 768px) {
    .ccontainer {
      flex-direction: column;
      align-items: center;
    }
  
    .cbox {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .cbox {
      width: 95%;
    }
  
    h3 {
      font-size: 1.2rem;
    }
  
    p {
      font-size: 0.9rem;
    }
  
    .con21 {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .map-container {
      padding-bottom: 75%; /* Adjust ratio for mobile devices */
    }
  }
  
  @media (max-width: 480px) {
    .map-container {
      padding-bottom: 100%; /* Square aspect ratio for very small screens */
    }
  }

.body{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30vh;
   /* background-image: url("../../assets/images/contact.jpg");*/

}
.ccontainer {
    display: flex;
    justify-content: space-between; /* or use 'space-around', 'space-evenly', etc., as needed */
    align-items: center; /* align items vertically in the center */
    margin-bottom: 25px;
    margin-left: 20px;
    margin-right: 20px;
    /* border-radius: 15px; */
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    animation: fadeIn 1s ease-in-out;
}

.cbox {
    background-color: white;
    color: white;
    font-weight: bolder;
    flex: 1; 
    margin: 0 5px; 
    padding: 5px; 
    text-align: center; 
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .cbox:hover{
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0,0,0,0.2);
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px); 
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

.con21 {
    width: 200px;
    height: 150px;
    padding-bottom: 10px;
  }

  .cbox h3{
    font-size: 30px;
    color: black;
    margin-bottom: 15px;
    padding-top: 10px;
  }
  .cbox p{
    font-size: 18px;
    margin-bottom: 10px;
    color: black;
    margin-top: 10px;
    padding-left: 60px;
  }
  .ch {
    position: relative;
    font-size: 6rem;
    letter-spacing: 15px;
    color: green;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    -webkit-box-reflect: below 1px linear-gradient(transparent, black);
    line-height: 0.7em;
    outline: none;
    animation: animate 5s linear infinite;
  }
  .map-container {
    position: relative;
    width: 100%;
    padding-bottom: 46.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    height: 0;
    padding-top: 100px;
    overflow: hidden;
    margin-bottom: -320px;
  }
  
  .map1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60%;
    border: 0;
  }
