/* Main Container */
.sets-us-apart-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  color: white;
  margin-bottom: -200px;
}

/* Background Video */
.WSbackground-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75%; /* Cover most of the container */
  object-fit: cover;
  z-index: -1; /* Ensure the video stays behind the content */
}

/* Main Content Section */
.sets-us-apart {
  position: relative;
  width: 80%;
  margin: 4rem auto;
  background-color: rgba(245, 254, 188, 0.7); /* Semi-transparent background */
  border-radius: 50px;
  padding: 50px 10%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1; /* Ensure content stays above the video */
}

.sets-us-apart h2 {
  text-align: center;
  color: #50160f;
  margin-bottom: 40px;
}

.sets-us-apart .content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 1rem;
}

.sets-us-apart .column {
  width: 30%; /* Three columns for large screens */
  margin-bottom: 20px;
}

.sets-us-apart .column ul {
  list-style: none;
  padding: 0;
}

.sets-us-apart .column li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px; /* Increase spacing between list items */
}

.sets-us-apart .icon {
  font-size: 2.5rem;
  color: #50160f;
  margin-right: 20px;
  flex-shrink: 0;
}

.sets-us-apart h3 {
  color: #333;
  margin: 0;
  line-height: 1.2;
  font-size: 20px;
}

.sets-us-apart p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

/* Medium Screens (max-width: 1024px) */
@media (max-width: 1024px) {
  .sets-us-apart-container {
    height: 80vh; /* Reduce height for medium screens */
  }
  
  .WSbackground-video {
    height: 60%; /* Adjust video height */
  }
  
  .sets-us-apart {
    width: 90%; /* Adjust container width */
    padding: 40px 5%; /* Reduce padding */
  }

  .sets-us-apart .column {
    width: 45%; /* Two columns for medium screens */
  }
}

/* Small Screens (max-width: 768px) */
@media (max-width: 768px) {
  .sets-us-apart-container {
    height: 80vh; /* Maintain reduced height */
  }
  
  .WSbackground-video {
    height: 40%; /* Further adjust video height */
  }
  
  .sets-us-apart {
    width: 95%; /* Further adjust container width */
    padding: 30px 4%; /* Reduce padding further */
  }

  .sets-us-apart .content {
    flex-direction: column;
    align-items: center; /* Center content */
  }

  .sets-us-apart .column {
    width: 100%; /* Single column layout */
  }
}

/* Extra Small Screens (max-width: 480px) */
@media (max-width: 480px) {
  .sets-us-apart-container {
    height: 140vh; /* Increase height to accommodate content */
  }

  .WSbackground-video {
    height: 60rem; /* Increase video height significantly */
  }
  
  .sets-us-apart {
    padding: 20px 2%; /* Minimal padding */
  }

  .sets-us-apart h2 {
    font-size: 1.5rem; /* Adjust heading size */
  }

  .sets-us-apart .icon {
    font-size: 2rem; /* Adjust icon size */
  }

  .sets-us-apart p {
    font-size: 0.9rem; /* Adjust text size for readability */
  }
}
