* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.header {
  background-image: url("../../assets/images/bg.jpg");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 10vh;
  display: flex;
  margin-top: 100px;
  margin-bottom: 100px;
}

.recontainer {
  max-width: 1200px;
  margin: auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10rem;
}

.recontainer_left h1 {
  margin-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: 500;
  color: red;
}

.recontainer-left p {
  color: white;
  margin-bottom: 2rem;
}

.recontainer-left button {
  padding: 1rem 2rem;
  margin-top: 1rem;
  outline: none;
  border: none;
  border-radius: 5px;
  background: black to right, blue, purple;
  color: black;
  font-size: 1rem;
  cursor: pointer;
}

.recontainer-right {
  display: grid;
  gap: 2rem;
  margin-right: 20px;
}

.recard {
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  background-color: white;
  border-radius: 1rem;
  box-shadow: 5px 5px 20px black;
  cursor: pointer;
}

.recard img {
  max-width: 75px;
  border-radius: 100%;
}

.recard_content {
  display: flex;
  gap: 1rem;
}

.recard_content span i {
  font-size: 2rem;
  color: white;
}

.recard_details p {
  font-style: italic;
  color: black;
  margin-bottom: 1rem;
}

.recard_details h4 {
  text-align: right;
  color: blue;
  font-size: 1rem;
  font-weight: 500;
}

.video2 {
  width: 500px;
}/*# sourceMappingURL=review.css.map */