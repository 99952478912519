.banner {
  position: relative;
  height: 500px;
  margin-top: 50px;
  margin-bottom: 350px;

}
  
.banner-video2 {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
  width: 1500px;
  }
  
.banner-video2 video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 100%;
}
  
.banner-text2 {
  color: #000000;
  background: rgb(221, 221, 221);
  line-height: 4vh;
  font-size: 17px;
  padding-left: 10px;
  padding-right: 20px;
}
  
.banner h1 {
  font-size: 3rem;
  margin-top: 100px;

}
.banner-text2{
  text-align: justify;
  

}
  
.banner-text2 p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

@media (max-width: 1024px) {
  .banner-video {
    height: 200px;
  }
  .video-banner__text {
    font-size: 2.5em;
  }
}

@media (max-width: 768px) {
  .banner-video {
    height: 150px;
  }
  .video-banner__text {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .video-banner {
    height: 100px;
  }
  .video-banner__text {
    font-size: 1.5em;
  }
}