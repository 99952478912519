.pkg-banner-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .pkg-banner-item {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .pkg-main-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .pkg-places {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .pkg-place-item {
    width: 250px;
    height: 200px;
    overflow: hidden;
    position: relative;
  }
  
  .pkg-place-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%); */
    transition: transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.596); /* Add box-shadow */
}
  
  .pkg-place-item:hover .pkg-place-image {
    transform: scale(1.1);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Enhanced box-shadow on hover */

  }

  .pkg-title{
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 2.5rem;
    color: #fff;
  }
  