/* src/components/Heading.css */
/* .heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.heading-container{
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column; /* Default is row for larger screens */
  overflow: hidden;
}
.heading-container h2 {
  text-align: center;
  margin-bottom: 0.1rem;
  font-size: 2.5rem;  
  color: #000000;
}

.heading-container h6 {
  text-align: center;
  margin-bottom: -0.3rem;
  font-size: 3.5rem;  
  color: #ffffff;;
}
.heading-container hr{
  width: 80%;
  border-top: 2px solid #ff9900; /* Replace with your desired color */
}
