.why-choose-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden; /* Ensures the video doesn't overflow */
}

.video-banner__video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1; /* Send the video behind the content */
}

.why-choose-content {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 10px;
  max-width: 80%;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.whychooseheader {
  color: #50160f;
  font-size: 2.5rem;
  margin: 10px 0;
}

.paragraph {
  color: black;
  font-size: 1.2rem;
  margin: 10px 0;
}
