.faq-container {
  width: 80%; /* Scales well on larger screens */
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-header {
  text-align: center;
  color: #50160f;
  font-size: 2.5rem; /* Large font for prominent display */
  margin-bottom: 20px;
}

.faq-list {
  margin-top: 20px;
}

.faq-item {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  transition: box-shadow 0.3s ease; /* Smooth transition on hover */
}

.faq-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Slightly stronger shadow on hover */
}

.faq-question {
  font-size: 1.5rem;
  color: #50160f;
  margin-bottom: 10px;
}

.faq-answer {
  font-size: 1.2rem;
  color: #333;
  margin: 0;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .faq-container {
    width: 95%; /* Increases width on smaller screens for better use of space */
  }

  .faq-header {
    font-size: 2rem; /* Adjusted for smaller screens */
  }

  .faq-question {
    font-size: 1.3rem; /* Adjusted for readability on smaller screens */
  }

  .faq-answer {
    font-size: 1rem; /* Adjusted for readability on smaller screens */
  }
}

@media (min-width: 1200px) {
  .faq-container {
    width: 70%; /* Slightly narrower on large screens */
  }

  .faq-header {
    font-size: 3rem; /* Increase font size for large screens */
  }

  .faq-question {
    font-size: 1.75rem; /* Increase font size for large screens */
  }

  .faq-answer {
    font-size: 1.5rem; /* Increase font size for large screens */
  }
}