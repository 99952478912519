.audio-player-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.audio-control-button {
  background-color: #ff9900;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px; /* Add some space between the buttons */
}

.audio-control-button:hover {
  background-color: #892e24;
}

.whatsapp-btn {
  background-color: #25d366;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  text-decoration: none;
}

.whatsapp-btn:hover {
  background-color: #128c7e;
}
