.sectiontitle2 {
    padding-top: 0; /* Reduce the top padding off the section */
    text-align: center;
}

.clienth2 {
    color: #50160f;
    font-size: 2.8rem; 
    position: relative;
    font-family: 'Kanit', sans-serif;
    margin-bottom: 1rem;
}

.reviewp {
    color: rgb(103, 100, 100);
    font-family: 'Courier New', Courier, monospace;
    padding-top: 30px;
    height: 100px;
}

.slider-container2 {
    width: 90%;
    margin: 0 auto;
    padding: 1rem 2rem; /* Adjusted padding to control height */
    padding-bottom: 3rem;
    padding-left: 4rem;
    padding-right: 4rem;
}


.custom-slick-slider .slick-list {
    margin: 0 -15px; /* Adjust negative margin to account for padding on slides */
}

.custom-slick-slider .slick-reviewslide2 {
    padding: 0 20px; /* Adjust padding to create gap between columns */
}

.reviewslide2 {
    position: relative;
    text-align: center; /* Center the text below the image */
    background-color: rgba(255, 255, 0, 0.2);
    display: flex;
    border-radius: 80px;
    padding: 1rem;
    margin-bottom: 20px;
}



.review-image2 {
    width: 40%;
    height: 90px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 70px;
    align-items: center;
}

.info2 {
    color: rgb(0, 0, 0);
    padding: 1rem;
    border-radius: 5px;
    margin-top: 0.2rem;
}

.info2 h3 {
    margin: 0;
    font-size: 1.2rem;
}

.info2 p {
    margin: 0.5rem 0 0;
    font-size: 1rem;
}

.award2 {
    width: 400px;
    height: 80px;
    margin-left: 500px;
    margin-right: 200px;
    margin-top: 90px;
    margin-bottom: 20px;
}

.award {
    width: 400px;
    height: 130px;
    margin-left: 500px;
    margin-right: 200px;
    margin-top: 70px;
    margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .reviewslide2 {
        padding: 0.5rem;
        margin: 0.5rem; /* Adjust margin for responsiveness */
    }

    .info2 h3 {
        font-size: 1rem;
    }

    .info2 p {
        font-size: 0.9rem;
    }
}

@media (max-width: 768px) {
    .slider-container2 {
        width: 100%;
        padding: 1rem 0;
    }

    .reviewslide2 {
        padding: 0.5rem;
        margin: 0.5rem; /* Adjust margin for responsiveness */
    }

    .info2 h3 {
        font-size: 1rem;
    }

    .info2 p {
        font-size: 0.9rem;
    }
}
