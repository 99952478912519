.sectiontitle {
    padding-top: 0; /* Increase the top padding off the section */
    text-align: center;
}

.sectiontitle h1 {
    font-family: 'Kanit', sans-serif;
    color: #50160f;
    font-size: 2.8rem; 
    margin-bottom: 1rem; 
}

.goicon{
    color: #50160f;
    margin-left: 20px;
    padding-top: 2rem;
    margin-top: 7px;
}

.slider-container {
    width: 100%;
    margin: 0 auto;
    padding-top: 1rem; /*Adjusted padding to control height*/
    padding-left: 4rem;
    padding-right: 4rem;
    height: 50vh;

}
.slider-container .slick-dots {
    bottom: 150px; /* Adjust this value to move the dots closer to the slides */
    /* You can also use margin if necessary */
    color: #50160f;
}
.custom-slick-slider .slick-list {
    margin: 0 -15px; /* Adjust negative margin to account for padding on slides */
}

.custom-slick-slider .slick-slide {
    padding: 0 20px; /* Adjust padding to create gap between columns */
}

.slide {
    position: relative;
    text-align: center; /* Center the text below the image */
    width: 240px;
}

.hotel-image {
    width: 100%;
    height: 200px; /* Height of the image */
    object-fit: cover; /* Ensure image covers the rectangle shape */
    border-radius: 20px;
}

.info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(212, 200, 199, 0.6); 
    color: #50160f;
    padding: 0.5rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7); /* Text shadow for better readability */
}

.info h3 {
    margin: 0;
    font-size: 1.4rem; 
    margin-bottom: 0;
    padding-bottom: 0;
    font-family: 'Kanit', sans-serif;
    position: relative;
    color: transparent; /* Make text transparent */
    background: #50160f; /* Fallback color */
    background-clip: text;
    -webkit-background-clip: text;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.6); /* Light shadow for the embedded look */
}

.info p {
    margin: 0.3rem 0 0;
    font-size: 1rem; /* Font size for paragraph */
    color: #50160f;
    margin-top: 0;
    padding-top: 0;
    text-align: center;
}

.hotel-link {
    text-decoration: none; /* Remove underline */
    color: #50160f; /* Set your desired color */
}

.hotel-link:hover {
    color: #8b0000; /* Optional: change color on hover if desired */
}

/* Responsive Design */
@media (max-width: 1024px) {
    .custom-slick-slider .slick-list {
        margin: 0 -10px;
    }

    .custom-slick-slider .slick-slide {
        padding: 0 10px;
    }

    .info h3 {
        font-size: 0.9rem;
    }

    .info p {
        font-size: 0.8rem;
    }
}

@media (max-width: 768px) {
    .slider-container {
        width: 100%;
        /* padding: 0.5rem 0; Reduced padding */
        /* padding-left: 20px; */
    }

    .custom-slick-slider .slick-list {
        margin: 0 -5px;
    }

    .custom-slick-slider .slick-slide {
        padding: 0 5px;
    }

    .info h3 {
        font-size: 0.9rem;
    }

    .info p {
        font-size: 0.8rem;
    }
}
