/* src/components/TopBar/TopBar.css */
.top-bar {
    width: 100%;
    height: 40px;
    /* background: url('../../assets/images/nav-bg8.jpg') no-repeat center center; */
    /* background-color: #f5f2ef; Background color for the top bar */
    position: relative;
    display: flex;
    justify-content: space-between; /* Align items to the right */
    align-items: center;
    padding: 10px; /* Padding for spacing */
    box-sizing: border-box;
  }

  .video-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1; /* Place video behind other content */
    overflow: hidden;
  }
  
  .video-bg video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none; /* Prevent interaction with the video */

  }
  
  .social-media-icons a {
    color: #892e24; /* Icon color 0b0b0b*/
    margin-left: 15px; /* Spacing between icons */
    font-size: 14px; /* Icon size */
    text-decoration: none;
  }
  
  .social-media-icons a:hover {
    color: #ff9933; /* Change icon color on hover */
  }

  .topbar-right-side-content {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Align items to the right */

  }

  .contact-links {
    display: flex;
    flex-direction: row; /* Change to row to display items next to each other */
    align-items: center; /* Align items vertically center */
}

.contact-link {
    color: #892e24; /* Adjust text color as needed */
    text-decoration: none;
    margin-right: 15px; /* Add margin to the right instead of bottom for spacing */
    font-size: small;
    display: flex;
    align-items: center;
}

.contact-links a:hover {
  color: #ff9933;
}

.contact-link svg {
    margin-right: 5px;
}

/* Responsive styles */
@media screen and (max-width: 960px) {
  .top-bar {
    flex-direction: row;
    align-items: flex-start;
    height: auto;
  }

  .social-media-icons {
    margin-bottom: 10px;
    justify-content: center;
    width: 100%;
  }

  .social-media-icons a {
    margin-left: 10px;
    font-size: 16px;
  }

  .topbar-right-side-content {
    flex-direction: column;
    align-items: flex-start;
    width: 100%; /* Make it full width */
  }

  .contact-links {
    flex-direction: column;
    align-items: flex-start;
    width: 100%; /* Make it full width */
  }

  .contact-link {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%; /* Make each contact link full width */
  }

  .contact-link:last-child {
    margin-bottom: 0; /* Remove bottom margin from last contact link */
  }
}
