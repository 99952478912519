.description2-container {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: space-between;  
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding-left: 180px;
    padding-right: 180px;
    padding-bottom: 20px;
}

.descrip-Bg-Video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;
}

.descrip-Bg-Video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-width: 100%; /* Ensure the video width doesn't shrink less than the container */
    min-height: 100%;
}

.description2 {
    width: 60%; /* Adjust width to avoid overlap */
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1;
}

.description2 h1 {
    text-align: left;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: 900;
    color: brown;
}

.description2-text {
    width: 100%;
    text-align: left;
    font-size: 1.2rem;
}

.description-img {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.description-img img {
    max-width: 100%;
    height: auto;
}

/* Media Queries for different screen sizes */
@media (max-width: 1200px) {
    .description2-container {
        flex-direction: column;
        height: auto;
        padding-left: 60px;
        padding-right: 60px;
    }

    .description2 {
        width: 100%;
        padding: 20px 0;
    }

    .description2 h1 {
        font-size: 2rem;
        width: 70%;
    }

    .description2-text {
        font-size: 1rem;
        width: 70%;

    }

}

@media (max-width: 768px) {
    .description2-container {
        flex-direction: column;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
    .description2 {
        width: 100%;
        padding: 20px 0;

    }
    .description2 h1 {
        font-size: 1.5rem;
        /* width: 80%; */

    }

    .description2-text {
        font-size: 0.9rem;
        /* width: 80%; */

    }
    

}

@media (max-width: 576px) {
    .description2-container {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
        padding-left: 10px;
        padding-right: 10px;
    }

    .description2 {
        width: 100%;
        padding: 20px 0;

    }

    .description2 h1 {
        font-size: 1.2rem;
        /* width: 80%; */

    }

    .description2-text {
        font-size: 0.8rem;
        width: 80%;

    }
     
}
