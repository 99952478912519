.sectiontitle {
    padding-top: 2rem;
    text-align: center;

}

.sectiontitle h1 {
    font-family: 'Kanit', sans-serif;
    color: #50160f;
    font-size: 2.8rem; 
    margin-bottom: 1rem; 
}
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    /* opacity: 0.5; */
}

.blog-section {
    position: relative;
    z-index: 1;
    color: white;
    padding-bottom: 20px; /* Reduced padding-bottom */
}

.blog-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap; /* Allow wrapping for responsiveness */
}


.blog-post {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    flex: 1 1 30%; /* Ensure blog posts take equal space */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    margin: 0.5rem;
}


.blog-post img {
    width: 100%;
    height: 150px; /* Reduced height */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
}

.blog-content {
    padding: 0.5rem; /* Reduced padding */
    text-align: center;
}

.blog-content h3 {
    margin-top: 0;
    font-size: 1.3rem; /* Reduced font size */
    color: #333;
}

.blog-content p {
    color: #666;
    font-size: 1rem; /* Reduced font size */
}

.read-more {
    text-decoration: none;
    color: #df5c04; /* Link color */
    font-weight: bold;
}

.read-more:hover {
    text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .blog-container {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .blog-post {
        width: 45%; /* Adjust width for medium screens */
        margin-bottom: 1rem; /* Reduced margin-bottom */
    }
}

@media (max-width: 768px) {
    .blog-container {
        flex-direction: column;
        align-items: center;
    }

    .blog-post {
        width: 100%;
        margin-bottom: 1rem; /* Reduced margin-bottom */
    }
}

@media (max-width: 480px) {
    .sectiontitle h1 {
        font-size: 1.8rem; /* Adjust font size for small screens */
    }

    .blog-post {
        width: 100%;
        margin-bottom: 1rem; /* Reduced margin-bottom */
    }

    .blog-content h3 {
        font-size: 1.2rem; /* Adjust font size for small screens */
    }

    .blog-content p {
        font-size: 0.9rem; /* Adjust font size for small screens */
    }
}
