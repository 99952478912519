.about-us-banner-section {
    width: 100%;
    height: 400px; /* Adjust the height as needed */
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Align content to the start (left) */
    background-size: cover;
    background-position: center;
    color: white;
    position: relative; /* Ensure the text content is positioned over the background */
}

.about-us-banner-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    opacity: 0.5; /* Adjusted opacity for better readability */
    z-index: 1;
}

.about-us-banner-content {
    position: relative; /* Position the content over the background */
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center; /* Align items to the start (left) */
    justify-content: center; /* Center content vertically within the banner */
    /* padding-left: 15rem; */
    width: 100%; /* Ensure the content takes the full width */
}

.about-us-banner-section h1 {
    font-size: 5rem;
    color: white;
    text-align: center; /* Align text to the left */
    margin-bottom: 1rem; /* Add some space below the heading */
    text-shadow: 2px 2px 4px #090303; /* Offset by 2px horizontally and vertically, 4px blur, dark maroon color */
}

/* Responsive Design */
@media (max-width: 1024px) {
    .about-us-banner-section {
        height: 20rem; /* Adjust height for medium screens */
    }

    .about-us-banner-content {
        padding-left: 5rem; /* Reduce left padding for medium screens */
    }

    .about-us-banner-section h1 {
        font-size: 2.5rem; /* Reduce font size for medium screens */
    }
}

@media (max-width: 768px) {
    .about-us-banner-section {
        height: 18rem; /* Adjust height for small screens */
    }

    .about-us-banner-content {
        padding-left: 2rem; /* Reduce left padding for small screens */
    }

    .about-us-banner-section h1 {
        font-size: 2rem; /* Reduce font size for small screens */
    }

}

@media (max-width: 480px) {
    .about-us-banner-section {
        height: 15rem; /* Adjust height for extra small screens */
    }

    .about-us-banner-content {
        padding-left: 1rem; /* Reduce left padding for extra small screens */
    }

    .about-us-banner-section h1 {
        font-size: 1.5rem; /* Reduce font size for extra small screens */
    }
}
