.tips-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .video-banner {
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 90%;
    margin-bottom: 20px;
  }
  
  .tips-header {
    text-align: center;
    color: #50160f;
    font-size: 2.5rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .tips-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .tip-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
    transition: box-shadow 0.3s ease;
  }
  
  .tip-item:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .tips-container {
      width: 95%;
    }
  
    .tips-header {
      font-size: 2rem;
    }
  
    .tip-item {
      font-size: 1rem;
    }
  }
  