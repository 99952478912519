.video-container {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background: #000;
}

.youtube-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 56.25vw; /* 16:9 aspect ratio (height = width * 9 / 16) */
    transform: translate(-50%, -50%);
    background: #000;
}

.mute-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    z-index: 1000;
}

.mute-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
    .video-container {
        height: 30vh;
    }

    .youtube-video {
        height: 56.25vw; /* Maintain aspect ratio */
    }

    .mute-button {
        bottom: 10px;
        right: 10px;
    }
}

@media (max-width: 480px) {
    .video-container {
        height: 20vh;
    }

    .youtube-video {
        height: 56.25vw; /* Maintain aspect ratio */
    }

    .mute-button {
        width: 40px;
        height: 40px;
        font-size: 18px;
    }
}
