.reserve-now-form-container{
    margin-bottom: 0px;
}
 .reserve-now-form {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    /* border-radius: 8px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .reserve-now-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .reserve-now-form input,
  .reserve-now-form select,
  .reserve-now-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: 'Kanit', sans-serif;
  }
  
  .reserve-now-form button {
    width: 100%;
    padding: 12px;
    /* background-color: #892e24; */
    background-color: #ff9900;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    font-family: 'Kanit', sans-serif;

  }
  
  .reserve-now-form button:hover {
    background-color: #892e24;
  }
  
  .form-status {
    margin-top: 20px;
    font-size: 16px;
    color: green;
    text-align: center;
  }
  