@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.Footer {
    padding: 2rem 0 0 0; /* Remove bottom padding */
    background: url(../../assets/images/footer.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    width: 100%; /* Ensure full width for the footer */
    margin: 0 auto; /* Center the footer */
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 80%; /* Set container width to 70% */
    margin: 0 auto; /* Center the container */
    padding-bottom: -10px;
}

.row {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1.5fr 0.9fr; /* Increase the first column width */
    width: 100%;
}

.column {
    width: 100%; /* 100% width for columns */
    padding: 0;
}

.logoDiv img {
    width: 100%;
    max-width: 175px;
    height: auto;
    margin-top: -30px;
    margin-left: 15px;
}

.column:first-of-type p {
    font-size: 1rem; /* Adjust font size */
    font-weight: 300; /* Make the text bold */
    color: white; /* Change text color */
    margin-top: -8px; /* Add some top margin */
    line-height: 1.2; /* Adjust line height */
    margin-right: 4.5rem;
}

.footer-icons {
    display: flex;
    gap: 0.5rem;
    margin-top: 10px;
    font-size: 1.8rem; /* Icon size */
    text-decoration: none;
    padding-left: 0;
    text-align: center;
}

.footer-icons svg {
    padding: 0.3rem;
    background: #df5c04;
    color: #f2bc97;
    border-radius: 50%;
    transition: background 0.6s, color 0.6s;
}

.footer-icons svg:hover {
    background: #ff9933;
    color: #fff;
}

.column h5 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #f9f8f8;
    padding-bottom: 10px;
}

.column h5 u{
    text-decoration: none; /* Remove the default underline */
    border-bottom: 2px solid #df5c04; /* Custom underline */
    padding-top: 5px; /* Adjust this value to set padding from text */
    display: inline-block; /* Ensure it respects padding and borders */
}

.column h6 {
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    color: #df5c04;
}

.column ul {
    list-style: disc;
    padding-left: 0;
    margin: 0; /* Remove default margin */
}

.column li {
    margin: 0; /* Remove the default margin */
    padding-bottom: 0; /* Remove padding if needed */
    margin-bottom: -42px; /* Adjust to remove space between items */
}

.column li::before {
    content: "";
    margin-right: 0;
}

.column ul a {
    text-decoration: none;
    color: #f9f8f8;
    font-size: 1rem;
    transition: color 0.3s;
}

.column ul a:hover {
    color: #ff9933;
}

.column p {
    display: flex;
    align-items: center;
    color: #f9f8f8;
    font-size: 1rem;
    transition: color 0.3s;
    margin-top: -3px;
}

.column p svg {
    margin-right: 0.5rem;
    color: #ff9933;
}

.column table {
    width: 100%;
    border-collapse: collapse; /* Ensure borders are merged */
}

.column tr{
    padding-bottom: 1rem;
}

.column td {
    color: #f9f8f8;
    font-size: 0.9rem;
    vertical-align: middle;
    padding-right: 1rem;
}

.column td:first-child {
    text-align: center; /* Center align icons */
}

.column td:nth-child(2) {
    padding-left: 0.5rem; 
}

.column td svg {
    font-size: 1.4rem; /* Set icon size */
    padding: 0.3rem;
    background: #df5c04;
    color: #f2bc97;
    border-radius: 50%;
    transition: background 0.6s, color 0.6s;
}

.column td svg:hover {
    background: #ff9933;
    color: #fff;
}

.column b {
    display: block;
    font-weight: 700;
    color: #ff9933;
}

.Last-footer {
    padding-bottom: 0.5px; /* Remove bottom padding */
    margin-bottom: 0; /* Remove bottom margin */
}

.Last-footer p {
    font-size: 1rem;
    font-weight: 700;
    color: #f9f8f8;
    transition: color 0.3s;
    text-align: center;
}

.Last-footer hr {
    border: none;
    height: 2px;
    background-color: #f9f8f8;
    margin: 10px 0;
}

.otherLogoDiv1 img {
    /* width: 100%; */
    max-width: 200px;
    height: 100px;
    margin: 0 auto; 
    margin-top: 2rem;
}

.otherLogoDiv2 img {
    /* width: 100%; */
    max-width: 150px;
    height: 80px;
    margin-left: 3.5rem;
    
}

.otherLogoDiv3 img {
    /* width: 100%; */
    max-width: 150px;
    height: 80px;
    margin-top: 10px;
    margin-left: 3.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .row {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .column {
        max-width: 100%;
    }
}
