/* Base Styles (no changes) */
.SGbody2 {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    padding: 2rem;
    position: relative;
}

.video-bg1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;
}
  
.video-bg1 video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SGslider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    text-align: center;
    margin-bottom: 0;
    z-index: 1;
    margin-left: 25rem;
}

.SGslider {
    position: relative;
    width: 200px;
    height: 200px;
    transform-style: preserve-3d;
    animation: rotate 30s linear infinite;
    margin-top: 5rem;
    z-index: 1;
}

@keyframes rotate {
    0% {
        transform: perspective(1000px) rotateY(0deg);
    }
    100% {
        transform: perspective(1000px) rotateY(360deg);
    }
}

.SGslider span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform-style: preserve-3d;
    transform: rotateY(calc(var(--i) * 40deg)) translateZ(350px);
}

.SGslider span img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 2s;
}

.SGcontent p {
    font-family: 'Kanit', sans-serif;
    font-size: 1.8rem;
    font-weight: 800;
    color: #50160f;
    margin-left: 11rem;
    margin-right: 6rem;
    margin-top: -50px;
    justify-content: center;
    text-align: center;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .SGbody2 {
        height: auto; /* Adjust height for smaller screens */
        padding: 1rem;
    }

    .SGslider-container {
        width: 90%;
        margin-left: 0; /* Center the slider for medium screens */
    }

    .SGcontent p {
        font-size: 1.6rem;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-top: 5rem;
    }
}

@media (max-width: 768px) {
    .SGslider-container {
        width: 100%;
        margin-left: 0; /* Center the slider for small screens */
    }

    .SGslider {
        width: 180px; /* Adjust slider size for smaller screens */
        height: 180px;
        margin-top: 3rem;
    }

    .SGcontent p {
        font-size: 1.4rem;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 4rem;
    }

    /* Add more padding to the bottom of the section */
    .SGbody2 {
        padding-bottom: 3rem;
    }
}

@media (max-width: 480px) {
    .SGslider {
        width: 140px; /* Smaller slider size for mobile devices */
        height: 140px;
        margin-top: 2rem;
    }

    .SGcontent p {
        font-size: 1.2rem;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 3rem;
    }

    /* Ensure the background video fully appears on mobile */
    .video-bg1 video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    /* Add more padding to the bottom of the section */
    .SGbody2 {
        padding-bottom: 4rem;
    }

    /* Center the slider and content for mobile view */
    .SGslider-container {
        margin-left: 0; /* Center the slider on mobile */
        align-items: center; /* Ensure the slider and content are centered */
    }
}
