.pkg-slider-container {
    max-width: 124rem;
    padding: 4rem 1rem;
    margin: 0 auto;
    margin-top: -40px;
}

.pkg-slider-heading {
    padding: 1rem 0;
    font-size: 1.5rem;
    text-align: center;
}

.swiper_container {
    height: 20rem; /* Adjust container height as needed */
    padding: 1rem 0;
    position: relative;
}

.swiper-slide {
    width: 20rem; /* Adjust width to match the image size */
    height: 10rem; /* Adjust height to match the image size */
    transition: transform 0.5s ease-in-out;
}

@media (max-width: 500px) {
    .swiper_container {
        height: 20rem; /* Adjust container height for smaller screens */
    }
    .swiper-slide {
        width: 20rem !important;
        height: 10rem !important;
    }
    .swiper-slide img {
        width: 20rem !important;
        height: 10rem !important;
    }
}

.swiper-slide img {
    width: 30rem; /* Set image width to 200px */
    height: 15rem; /* Set image height to 100px */
    border-radius: 1rem;
    /* object-fit: cover; */
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.pkg-slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pkg-slider-controler .swiper-button-next,
.pkg-slider-controler .swiper-button-prev {
    background: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #892e24;
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
    cursor: pointer;
}

.pkg-slider-controler .swiper-button-next {
    margin-left: 0.5rem;
}

.pkg-slider-controler .swiper-button-prev {
    margin-right: 0.5rem;
}

.pkg-slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    position: relative;
    bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: #892e24;
}

@media screen and (max-width: 960px) {
    .swiper_container {
        height: 20rem; /* Adjust container height for smaller screens */
    }
    .swiper-slide {
        width: 20rem !important;
        height: 10rem !important;
    }
    .swiper-slide img {
        width: 20rem !important;
        height: 10rem !important;
    }
    
}