.refund-container {
  width: 100%;
  background-image: url('../../assets/images/descrip-bg.jpg'); 
  background-size: cover;
  background-position: center;
  padding: 10px; 
}

.refund-section {
  width: 80%;
  margin: 0 auto; /* Center the section */
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start (left) */
}

.refund-header {
  text-align: center; /* Center the main header */
  color: #50160f;
  font-size: 2.5rem;
  margin-bottom: 20px;
  width: 100%; /* Ensure it takes the full width */
}

.header2, .header3 {
  color: #50160f;
  text-align: left; /* Align headers to the left */
  width: 100%;
}

.header2 {
  color: #50160f;
  text-align: center; 
  width: 100%;
}


.header2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.header3 {
  font-size: 1rem;
  margin-bottom: 15px;
}

.refund-paragraph, .listterms li {
  color: black;
  text-align: left; /* Align text to the left */
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.listterms {
  color: black;
  text-align: left; /* Align text to the left */
  font-size: 1.2rem;
  margin-bottom: 10px;
  list-style: none;
}

.list2{
  color: black;
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 10px;
  margin-left: 4rem;
}

.icon {
  margin-right: 20px;
  font-size: 3rem;
}

.video-banner {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 90%;
}

.video-banner__video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.video-banner__overlay {
  position: relative;
  z-index: 1;
  text-align: center;
}

.video-banner__text {
  color: white; /* White text color */
  font-size: 6rem;
  text-shadow: 2px 2px 0 black, -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black; /* Black border */
  font-weight: bold; /* Make the text bold for better visibility */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .video-banner {
    height: 200px;
  }
  .video-banner__text {
    font-size: 2.5em;
  }
}

@media (max-width: 768px) {
  .video-banner {
    height: 150px;
  }
  .video-banner__text {
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .video-banner {
    height: 100px;
  }
  .video-banner__text {
    font-size: 1.5em;
  }
}
