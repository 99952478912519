
.destinationcontainer1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 10px;
}


.destination-list1 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    width: 80%;
    max-width: 1200px;
    margin: 2rem 0;
    padding: 20px;
}


.goicon1 {
    color: #50160f;
    margin-left: 20px;
    padding-top: 2.5rem;
    margin-top: 7px;
    transition: transform 0.3s, color 0.3s;
}

.goicon1:hover {
    transform: scale(1.2);
    color: #8b0000;
    animation: bounce 1s infinite;
}


.destination-item1 {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
}

.destination-item1:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.destination-image1 {
    width: 100%;
    height: 200px;
    object-fit: cover;
    animation: fadeIn 1s ease-in-out;
}


.destination-title1 {
    padding: 10px;
    text-align: center;
    background-color: rgba(152, 150, 150, 0.1);
    color: #50160f;
    font-size: 1.2em;
    position: relative;
    transition: background-color 0.3s;
}

.destination-title1 a {
    color: #50160f;
    text-decoration: none;
}

.destination-title1:hover {
    background-color: rgba(152, 150, 150, 0.3);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}


.sub-destination1 {
    margin-top: 10px;
    padding: 10px;
    border-top: 1px solid #ccc;
}


@media (max-width: 1024px) {
    .destination-list1 {
        gap: 15px;
        padding: 15px;
    }

    .destination-image1 {
        height: 180px; /* Adjust image height for medium screens */
    }

    .destination-title1 {
        font-size: 1.1em; /* Adjust font size for medium screens */
    }

    .sub-destination1 {
        padding: 8px; /* Adjust padding for medium screens */
    }
}

@media (max-width: 768px) {
    .destination-list1 {
        gap: 10px;
        padding: 10px;
    }

    .destination-image1 {
        height: 150px; /* Adjust image height for small screens */
    }

    .destination-title1 {
        font-size: 1em; /* Adjust font size for small screens */
    }

    .sub-destination1 {
        padding: 6px; /* Adjust padding for small screens */
    }
}

@media (max-width: 480px) {
    .destination-list1 {
        gap: 5px;
        padding: 5px;
    }

    .destination-image1 {
        height: 120px; /* Adjust image height for extra small screens */
    }

    .destination-title1 {
        font-size: 0.9em; /* Adjust font size for extra small screens */
    }

    .sub-destination1 {
        padding: 5px; /* Adjust padding for extra small screens */
    }
}
