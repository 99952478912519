.review-slider {
    width: 100%;
    /* height:70vh; */
    height: auto;
    margin: 0 auto;
    padding: 10px 0; /* Adjusted padding */
    padding-left: 6rem;
    padding-right: 6rem;
    margin-bottom: -170px;
    /* background-color: #fafafa; */
    overflow: hidden;
}

/* Section Title */
.sectiontitle2 {
    padding-top: 0; 
    text-align: center;
    margin-bottom: 40px;
}

.sectiontitle2 h1 {
    color: #851106;
    font-size: 2.5rem; 
    font-family: 'Kanit', sans-serif;
    font-weight: 600;
    margin-top: 20px;
}

.reviewp {
    color: rgb(103, 100, 100);
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    font-weight: 200;
    margin-top: -40px;
    font-size: 1.2rem;
}

/* Review Slider Styles */
.slick-slide {
    margin: 20px 0;
    margin: 0 10px; /* Adjust this value for the gap between slides */
    display: flex;
    justify-content: center;
}

/* Adjust the margin or padding for slick-dots */
.review-slider .slick-dots {
    bottom: 200px; /* Adjust this value to move the dots closer to the slides */
    /* You can also use margin if necessary */
    margin-top: -10px; /* Optional: Adjust this value to reduce the gap */
}


.review-card {
    position: relative;
    display: flex;
    background-color: #FBF5DF;
    border-radius: 10px;
    padding: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 450px;
    height: 240px;
    column-gap: 2rem;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    margin-left: 30px;
    margin-bottom: -10px;
}

.review-card:hover {
    transform: scale(1.03);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.review-img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 3px solid #50160f;
}

.review-content {
    flex: 1;
    text-align: center;
}

.review-name {
    font-size: 1.2rem;
    color: #50160f;
    margin-bottom: 8px;
}

.review-message {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.4;
}

/* Container Styles */
.slider-container2 {
    width: 90%;
    margin: 0 auto;
    padding: 1rem 2rem; 
    padding-bottom: 3rem;
    padding-left: 4rem;
    padding-right: 4rem;
    height: auto;
}

/* Review Slide Styling */
.reviewslide2 {
    position: relative;
    text-align: center;
    background-color: rgba(255, 255, 0, 0.2);
    display: flex;
    border-radius: 80px;
    padding: 1rem;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* Google Image and Award Styling */
.client-google-img {
    display: flex;
    align-items: center;
    margin-top: -30px; 
    width: 100%;
    text-align: center;
}

.award {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    object-fit: contain;
}

/* Responsive Design for Tablets and Mobile */
@media (max-width: 1024px) {
    .slider-container2 {
        width: 100%;
        padding: 1rem;
    }

    .review-img {
        width: 70px;
        height: 70px;
    }

    .review-name {
        font-size: 1.1rem;
    }

    .review-message {
        font-size: 0.95rem;
    }

    .client-google-img {
        margin-top: -20px;
    }

    .award {
        max-width: 90%;
    }
}

@media (max-width: 768px) {
    .slider-container2 {
        padding: 1rem;
    }

    .reviewslide2 {
        padding: 0.5rem;
        margin: 0.5rem;
        flex-direction: column;
    }

    .review-img {
        width: 60px;
        height: 60px;
    }

    .review-name {
        font-size: 1rem;
    }

    .review-message {
        font-size: 0.9rem;
    }

    .client-google-img {
        margin-top: -10px;
    }

    .award {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        object-fit: contain;
    }
}

@media (max-width: 480px) {
    .slider-container2 {
        padding: 1rem;
    }

    .sectiontitle2 h1 {
        font-size: 2rem;
    }

    .reviewp {
        font-size: 18px;
    }

    .reviewslide2 {
        padding: 0.3rem;
    }

    .review-img {
        width: 50px;
        height: 50px;
    }

    .review-name {
        font-size: 0.9rem;
    }

    .review-message {
        font-size: 0.8rem;
    }

    .client-google-img {
        margin-top: 0;
        padding: 0 10px;
    }

    .award {
        max-width: 100%;
        height: auto;
    }
}
