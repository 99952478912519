body {
  font-family: 'Arial', sans-serif;
}

.section{
  /* background-image: url(../../assets/images/descrip-bg.jpg); */
  padding: 40px;
  border-radius: 10px;
  opacity: 100px;
  /* background-color: rgb(229, 145, 35); */
}

.containerwhy {
  width: 80%;
  margin: 3rem auto;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

@keyframes fadeIn {
  0%, 100% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
}

.containerwhy h1 {
  color: #50160f;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 3rem;
  font-style: italic;
  animation: fadeIn 4s ease-in-out infinite;
}



p {
  color: #0b0a0a;
  line-height: 1.6;
  margin-bottom: 1rem;
  text-align: justify; 
}

ol {
  color: #333;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
}

ol li {
  margin-bottom: 0.5rem;
}

/* Responsive Design */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .containerwhy {
      width: 90%; /* Default width for large screens */
  }
  h1 {
      font-size: 2rem; /* Adjust font size for large screens */
  }
  h2 {
      font-size: 1.5rem; /* Adjust font size for large screens */
  }
  p {
      font-size: 1rem; /* Default font size */
  }
  ol {
      font-size: 1rem; /* Default font size */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991px) and (min-width: 768px) {
  .containerwhy {
      width: 95%; /* Adjust width for tablets */
  }
  h1 {
      font-size: 1.8rem; /* Adjust font size for tablets */
  }
  h2 {
      font-size: 1.4rem; /* Adjust font size for tablets */
  }
  p {
      font-size: 0.95rem; /* Adjust font size for tablets */
  }
  ol {
      font-size: 0.95rem; /* Adjust font size for tablets */
  }
}

/* Small devices (mobile, 576px and up) */
@media (max-width: 767px) {
  .containerwhy {
      width: 95%; /* Full width for mobile */
  }
  h1 {
      font-size: 1.6rem; /* Adjust font size for mobile */
  }
  h2 {
      font-size: 1.2rem; /* Adjust font size for mobile */
  }
  p {
      font-size: 0.9rem; /* Adjust font size for mobile */
  }
  ol {
      font-size: 0.9rem; /* Adjust font size for mobile */
  }
}

/* Extra small devices (phones in portrait mode, less than 576px) */
@media (max-width: 575px) {
  h1 {
      font-size: 1.4rem; /* Further adjust font size for very small screens */
  }
  h2 {
      font-size: 1rem; /* Further adjust font size for very small screens */
  }
  p {
      font-size: 0.85rem; /* Further adjust font size */
  }
  ol {
      font-size: 0.85rem; /* Further adjust font size */
  }
}
