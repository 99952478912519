.vision-mission {
  display: flex;
  justify-content: space-between;
  padding: 50px 10%;
}

.vision-mission .column {
  width: 45%;
  /* background-image: url(../../assets/images/bg.jpg); */
  object-fit: cover; /* Ensure image covers the rectangle shape */
  border-radius: 20px;
  background-color: rgba(245, 254, 188, 1);
}

.vision-mission h2 {
  color: #50160f;
  /* text-shadow:
      1px 1px 0 #f8f7f7,   
      -1px 1px 0 #f8f7f7,  
      1px -1px 0 #f8f7f7,  
      -1px -1px 0 #f8f7f7, 
      2px 2px 0 #f8f7f7,  
      -2px 2px 0 #f8f7f7,
      2px -2px 0 #f8f7f7,
      -2px -2px 0 #f8f7f7; */
  margin-bottom: 0;
  text-align: center;
  font-style: italic;
  font-size: 2rem;
  padding-top: 2rem;
}

.vision-mission .column p {
  color: #50160f;
  font-size: 1.1rem;
  line-height: 1.6;
  text-align: justify;
  padding: 0.2rem 2rem 1rem 2rem;
}

/* Responsive Design */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .vision-mission {
      padding: 50px 10%; /* Default padding for large screens */
  }
  .vision-mission .column {
      width: 45%; /* Default width for large screens */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 991px) and (min-width: 768px) {
  .vision-mission {
      padding: 30px 5%; /* Adjust padding for tablets */
  }
  .vision-mission .column {
      width: 48%; /* Adjust width for medium screens */
  }
}

/* Small devices (mobile, 576px and up) */
@media (max-width: 767px) {
  .vision-mission {
      flex-direction: column; /* Stack columns vertically on mobile */
      padding: 20px 5%; /* Adjust padding for smaller screens */
  }
  .vision-mission .column {
      width: 100%; /* Full width for mobile */
      margin-bottom: 20px; /* Add space between stacked columns */
  }
  .vision-mission h2 {
      font-size: 1.5rem; /* Adjust font size for mobile */
  }
  .vision-mission p {
      font-size: 1rem; /* Adjust font size for mobile */
      padding: 0.5rem 1rem 1rem 1rem; /* Adjust padding for mobile */
  }
}

/* Extra small devices (phones in portrait mode, less than 576px) */
@media (max-width: 575px) {
  .vision-mission h2 {
      font-size: 1.2rem; /* Further adjust font size for very small screens */
  }
  .vision-mission p {
      font-size: 0.9rem; /* Further adjust font size */
  }
}
